import React from "react";

// Components
import Button from "../Reusable/Button";

// Utils
import { handleOverrideClassName, handleOverrideConfig, handleRedirect } from "../../utils/CommonFunctions";

// Services
import i18n from "../../i18n";

const FC2 = ({ 
    item,
    theme,
    history,
    pageName
}) => {
    return (
        <div 
            className={`fc2 ${handleOverrideClassName(item.classname)}`} 
            style={
                Object.assign({
                    boxShadow: handleOverrideConfig(theme.dropShadow, item.dropShadow) && '0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)',
                    minHeight: !handleOverrideConfig(theme.showImage, item.showImage) && 'auto', 
                }, {})
            }
        >   
        
            {
                handleOverrideConfig(theme.showImage, item.showImage)
                &&
                <div className={`top-section ${handleOverrideClassName(item.topSectionClassname)}`}>
                    {
                        (item.image && item.image.path)
                        &&
                        <img src={item.image.path} alt={item.image.alt_text} />
                    }
                </div>
            }

            <div className={handleOverrideConfig(theme.showImage, item.showImage) ? `bottom-section ${handleOverrideClassName(item.bottomSectionClassname)}` : `bottom-section bottom-section-without-img ${handleOverrideClassName(item.bottomSectionClassname)}`}>

                {/* Title text */}

                {
                    item.title
                    &&
                    <h3 className={`header-text ${handleOverrideClassName(item.bottomSectionHeaderTextClassname)}`}>{i18n.t(item.title)}</h3>
                }  

                {/* Body Text */}

                {
                    item.description
                    && 
                    <p className={`bodyText ${handleOverrideClassName(item.bottomSectionBodyTextClassname)}`}>{i18n.t(item.description)}</p>
                }

                {/* Action Button */}

                <div className="button-wrapper" style={{ justifyContent: handleOverrideConfig(theme.buttonAlignMent, item.buttonAlignMent) === "left" ? "flex-start" : handleOverrideConfig(theme.buttonAlignMent, item.buttonAlignMent) === "right" ? "flex-end" : "center" }}>
                    <Button 
                        buttonType={handleOverrideConfig(theme.buttonType, item.buttonType)}
                        buttonClassname={handleOverrideClassName(item.buttonClassname)}
                        buttonText={item.buttonText}
                        handleRedirect={() => handleRedirect(item.button_action, history, item.buttonText, pageName)}
                        showButtonIcon={item.showButtonIcon}
                        buttonIconImage={item.buttonIconImage}
                        buttonIconPosition={item.buttonIconPosition}
                        buttonIconClassname={handleOverrideClassName(item.buttonIconClassname)}
                    />
                </div>
            </div>
        </div>
    );
}

export default FC2;
